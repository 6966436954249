<template>
  <div>
    <div class="input">
      <a-input-search
        v-model="searchText"
        placeholder="请输入地址"
        enter-button="搜索"
        size="large"
        @search="onSearch"
        :loading="loading"
      />
      <a-tree
        v-if="isShowSelect"
        :expanded-keys="expandedKeys"
        :auto-expand-parent="autoExpandParent"
        :tree-data="options"
        @expand="onExpand"
        class="tree-Data"
        @onChange="onChange"
        @select="onChange"
      >
      </a-tree>
    </div>
    <div id="container"></div>
  </div>
</template>

<script>
import { suggestion } from './api/commonMap';

const TMap = window?.TMap;

export default {
  name: 'index',
  data() {
    return {
      marker: null,
      map: null,
      point: {
        lat: 39.984104,
        lng: 116.307503
      },
      options: [],
      autoExpandParent: true,
      isShowSelect: false,
      expandedKeys: [],
      result: {
        lat: 39.984104,
        lng: 116.307503,
        addr: ''
      },
      markers: [],
      searchText: '',
      loading: false
    };
  },
  watch: {
    latLng: {
      handler(value) {
        this.point = {
          lat: value?.[1] ?? 39.984104,
          lng: value?.[0] ?? 116.307503
        };
        this.searchText = '';
        this.setmapCenter();
        this.createMarker(this.point);
      }
    }
  },
  mounted() {
    console.log(this.latLng);
    if (this.latLng) {
      console.log(this.latLng);
      this.point = {
        lat: this.latLng?.[1] ?? 39.984104,
        lng: this.latLng?.[0] ?? 116.307503
      };
    }
    this.initMap();
  },
  props: {
    latLng: {
      type: Array,
      default: undefined
    }
  },
  methods: {
    async search(keyword) {
      this.loading = true;
      const data = await suggestion({
        keyword
      });
      this.loading = false;
      if (data?.status !== 0) {
        this.options = [];
        return;
      }
      this.options = data.data.map((s) => {
        return {
          key: s.id,
          id: s.id,
          pId: s.id,
          value: s.id,
          title: `【${s.title}】${s.address}`,
          location: s.location
        };
      });
      this.isShowSelect = true;
      console.log(this.options);
    },
    initMap() {
      var center = new TMap.LatLng(this.point.lat, this.point.lng); //设置中心点坐标
      //初始化地图
      if (this?.$nextTick) {
        this.$nextTick(() => {
          this.map = new TMap.Map(document.getElementById('container'), {
            center: center,
            zoom: 17.2, // 设置地图缩放级别
            pitch: 43.5, // 设置俯仰角
            rotation: 45 // 设置地图旋转角度
          });
          //监听点击事件添加marker
          this.map.on('click', (evt) => {
            !this.marker
              ? (this.createMarker(evt.latLng), (this.point = evt.latLng))
              : this.removeMarker();

            this.$emit('success', {
              ...this.point
            });
          });
          this.map.on('tilesloaded', () => {
            this.createMarker(this.point, true);
          });
        });
      } else {
        document.getElementById('container').innerHTML = '';
        this.map = new TMap.Map(document.getElementById('container'), {
          center: center,
          zoom: 17.2, // 设置地图缩放级别
          pitch: 43.5, // 设置俯仰角
          rotation: 45 // 设置地图旋转角度
        });
        //监听点击事件添加marker
        this.map.on('click', (evt) => {
          !this.marker
            ? (this.createMarker(evt.latLng), (this.point = evt.latLng))
            : this.removeMarker();
        });
        this.map.on('tilesloaded', () => {
          this.createMarker(this.point, true);
        });
      }
    },
    setmapCenter() {
      this.map.setCenter(new TMap.LatLng(this.point.lat, this.point.lng));
    },
    createMarker(latLng, isCneter) {
      this.isShowSelect = false;
      let center = undefined;
      console.log(latLng);
      if (isCneter) {
        center = new TMap.LatLng(latLng.lat, latLng.lng);
      }
      //设置中心点坐标
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          marker: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
          })
        },
        geometries: [
          {
            styleId: 'marker',
            position: center || latLng,
            properties: {
              title: 'marker'
            }
          }
        ]
      });
      this.markers.push(this.marker);
    }, //移除marker事件
    removeMarker() {
      this.markers.forEach((item, idx) => {
        this.markers[idx].setMap(null);
      });
      this.markers = [];
      this.marker = null;
      this.isShowSelect = false;
    },
    onSearch(value) {
      if (!value) {
        return;
      }
      this.search(value);
    },
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      this.isShowSelect = false;
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onChange(value) {
      const data = this.options.find((s) => s.id === value[0]);
      if (this.marker) {
        this.removeMarker();
      }
      this.createMarker({
        ...data.location,
        height: 0
      });
      this.point = data.location;
      this.result = {
        ...this.point,
        addr: data.title
      };
      this.$emit('success', this.result);
      this.setmapCenter();
    }
  }
};
</script>

<style scoped lang="scss">
#container {
  width: 100%;
  height: 500px;
}
.input {
  margin: 0 0 10px 0;
  display: block;
  position: relative;
  height: 50px;
  z-index: 10001;
}
.tree-Data {
  position: absolute;
  height: 330px;
  overflow: hidden;
  display: block;
  width: 100%;
  /*强制文字在一行文本框内*/
  white-space: nowrap;
  /*溢出部分文字隐藏*/
  overflow: hidden;
  /*溢出部分省略号处理*/
  text-overflow: ellipsis;
  z-index: 99999;
  background: #ffffff !important;
  width: 100%;
  border: 1px solid #c1c1c1;
}
::v-deep .ant-tree-title {
  display: block;
  width: 100%;
  /*强制文字在一行文本框内*/
  white-space: nowrap;
  /*溢出部分文字隐藏*/
  overflow: hidden;
  /*溢出部分省略号处理*/
  text-overflow: ellipsis;
}
::v-deep .ant-input-group-addon {
  button {
    width: 80px;
  }
}
</style>
